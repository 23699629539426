import "./App.css";
import { Box, colors, Typography } from "@mui/material";
import Particles from "./Particles";

function App() {
  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          zIndex: 2,
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box sx={{ mb: 20, mx: 10 }}>
          <Box
            sx={{
              border: 1,
              borderRadius: 4,
              borderColor: "#009245",
              display: "inline-block",
              background: "#fff",
              borderStyle: "dashed",
            }}
          >
            <Box component="img" width={100} src="BizGrab.png" />
          </Box>
          <Typography variant="h1" sx={{ fontWeight: 900 }}>
            Launching Soon!
          </Typography>
          <Typography variant="h6" color="GrayText">
            We're currently working hard to make it live...
          </Typography>
        </Box>

        <Box>
          <Typography
            sx={{
              textTransform: "uppercase",
              position: "absolute",
              right: 24,
              bottom: 40,
              fontSize: "12px",
            }}
          >
            By solcall Services
          </Typography>
          <Typography
            href="https://solcall.com.au/"
            target="_blank"
            component="a"
            variant="p"
            sx={{
              color: colors.blue[700],
              textDecoration: "underline",
              position: "absolute",
              right: 24,
              bottom: 18,
            }}
          >
            solcall.com.au
          </Typography>
        </Box>
      </Box>

      <Box sx={{ position: "relative", zIndex: 1 }}>
        <Particles />
      </Box>
    </Box>
  );
}

export default App;
